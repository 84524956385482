import styled from 'styled-components';

export const HeroContainer = styled.div`
  margin-top: 100px;
  font-family: Roboto;
  position: relative;
  max-height: 306px;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 992px) {
    max-height: 230px;
  }
  @media (max-width: 768px) {
    max-height: 198px;
  }
  .hero-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

export const BreadCrumbFlex = styled.div`
  position: absolute;
  z-index: 100;
  padding: 24px 34px;
  top: 0%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, 0%);
  display: flex;
  align-items: baseline;
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #98A2B3;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    span {
      color: #FFFFFF;
    }
  }
  @media (max-width: 576px) {
    padding: 20px;
  }
`

export const Container = styled.div`
  position: relative;
  height: 306px;
  min-height: 100%;
  margin:auto;
  max-width: 1170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  h1 {
    color: #FFFFFF;
    font-weight: 900;
    font-size: 68px;
    line-height: 72px;
    text-align: center;
  }
  @media (max-width: 992px) {
    max-width: 770px;
    height: 230px;
    h1 {
      font-size: 50px;
      line-height: 60px;
    }
  }
  @media (max-width: 768px) {
    max-width: 506px;
    height: 198px;
    h1 {
      font-size: 38px;
      line-height: 48px;
    }
  }
`